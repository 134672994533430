import React, { useState, useEffect } from "react"
// Images
import Img1h from "../images/careers/gallery/img1h.jpg"
import Img4h from "../images/careers/gallery/img4h.jpg"
import Img5h from "../images/careers/gallery/img5h.jpg"
import Img7h from "../images/careers/gallery/img7h.jpg"
import Img8h from "../images/careers/gallery/img8h.jpg"
import Img9v from "../images/careers/gallery/img9v.jpg"
import Img10v from "../images/careers/gallery/img10v.jpg"
import Img11v from "../images/careers/gallery/img11v.jpg"
import Img12h from "../images/careers/gallery/img12h.jpg"
import Img13h from "../images/careers/gallery/img13h.jpg"
import Img14h from "../images/careers/gallery/img14h.jpg"
import Img15h from "../images/careers/gallery/img15h.jpg"
import Img16h from "../images/careers/gallery/img16h.jpg"
import Img17h from "../images/careers/gallery/img17h.jpg"
import Img18h from "../images/careers/gallery/img18h.jpg"
import Img19h from "../images/careers/gallery/img19h.jpg"
import Img20h from "../images/careers/gallery/img20h.jpg"
import Img21h from "../images/careers/gallery/img21h.jpg"
import Img22h from "../images/careers/gallery/img22h.jpg"
import Img23h from "../images/careers/gallery/img23h.jpg"
import Img24h from "../images/careers/gallery/img24h.jpg"
import Img25h from "../images/careers/gallery/img25h.jpg"
import Img26h from "../images/careers/gallery/img26h.jpg"
import Img27h from "../images/careers/gallery/img27h.jpg"
import Img28h from "../images/careers/gallery/img28h.jpg"
import Img29h from "../images/careers/gallery/img29h.jpg"
import Img30v from "../images/careers/gallery/img30v.jpg"
import Img31h from "../images/careers/gallery/img31h.jpg"
import Img33h from "../images/careers/gallery/img33h.jpg"
import Img35v from "../images/careers/gallery/img35v.jpg"
import Img36h from "../images/careers/gallery/img36h.jpg"
import Img37h from "../images/careers/gallery/img37h.jpg"
import Img38h from "../images/careers/gallery/img38h.jpg"
import Img39v from "../images/careers/gallery/img39v.jpg"
import Img40h from "../images/careers/gallery/img40h.jpg"
import Img41h from "../images/careers/gallery/img41h.jpg"
import Img42h from "../images/careers/gallery/img42h.jpg"
import Img43v from "../images/careers/gallery/img43v.jpg"
import Img44v from "../images/careers/gallery/img44v.jpg"
import Img45h from "../images/careers/gallery/img45h.jpg"
import Img47v from "../images/careers/gallery/img47v.jpg"
import Img48v from "../images/careers/gallery/img48v.jpg"
import Img54h from "../images/careers/gallery/img54h.jpg"
import Img55h from "../images/careers/gallery/img55h.jpg"
import Img56h from "../images/careers/gallery/img56h.jpg"
import Img57h from "../images/careers/gallery/img57h.jpg"
import Img58v from "../images/careers/gallery/img58v.jpg"
import Img61h from "../images/careers/gallery/img61h.jpg"
import Img62h from "../images/careers/gallery/img62h.jpg"
import Img66h from "../images/careers/gallery/img66h.jpg"
import Img67h from "../images/careers/gallery/img67h.jpg"
import Img69h from "../images/careers/gallery/img69h.jpg"
import Img70h from "../images/careers/gallery/img70h.jpg"
import Img71h from "../images/careers/gallery/img71h.jpg"
import Img72h from "../images/careers/gallery/img72h.jpg"

import "./Slides.css"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/pagination"
import { Pagination } from "swiper"

const Desktop = () => {
  const images = [
    {
      page: [Img1h, Img9v, Img28h, Img31h, Img4h],
    },
    {
      page: [Img5h, Img10v, Img29h, Img7h, Img8h],
    },
    {
      page: [Img12h, Img11v, Img13h, Img14h, Img15h],
    },
    {
      page: [Img16h, Img30v, Img17h, Img18h, Img19h],
    },
    {
      page: [Img20h, Img47v, Img21h, Img22h, Img23h],
    },
    {
      page: [Img24h, Img58v, Img25h, Img26h, Img27h],
    },
    {
      page: [Img33h, Img48v, Img36h, Img37h, Img38h],
    },
    {
      page: [Img40h, Img35v, Img41h, Img42h, Img45h],
    },
    {
      page: [Img54h, Img39v, Img55h, Img56h, Img57h],
    },
    {
      page: [Img61h, Img43v, Img62h, Img66h, Img67h],
    },
    {
      page: [Img69h, Img44v, Img70h, Img71h, Img72h],
    },
  ]
  const pagination = {
    clickable: true,
    renderCustom: function () {
      return "<span></span>"
    },
  }
  return (
    <>
      <Swiper
        pagination={pagination}
        modules={[Pagination]}
        slidesPerView={1}
        slidesPerGroup={1}
        spaceBetween={40}
      >
        {images.map((item, i) => (
          <SwiperSlide
            key={i}
            className="grid grid-cols-3 grid-rows-2 gap-0 lg:gap-x-4 lg:gap-y-0 items-center mt-4 pb-4"
          >
            {item.page.map((image, j) => (
              <img
                loading="lazy"
                key={j}
                src={image}
                id={"Img" + j}
                className=""
                alt=""
              />
            ))}
          </SwiperSlide>
        ))}{" "}
      </Swiper>
    </>
  )
}

const Mobile = () => {
  const imagesMobile = [
    {
      page: [Img1h, Img4h],
    },
    {
      page: [Img5h, Img8h],
    },
    {
      page: [Img7h, Img29h],
    },
    {
      page: [Img9v],
    },
    {
      page: [Img12h, Img13h],
    },
    {
      page: [Img14h, Img15h],
    },
    {
      page: [Img10v],
    },
    {
      page: [Img16h, Img17h],
    },
    {
      page: [Img18h, Img19h],
    },
    {
      page: [Img11v],
    },
    {
      page: [Img20h, Img21h],
    },
    {
      page: [Img22h, Img23h],
    },
    {
      page: [Img24h, Img25h],
    },
    {
      page: [Img26h, Img27h],
    },
    {
      page: [Img30v],
    },
    {
      page: [Img28h, Img31h],
    },
    {
      page: [Img33h, Img36h],
    },
    {
      page: [Img35v],
    },
    {
      page: [Img37h, Img38h],
    },
    {
      page: [Img39v],
    },
    {
      page: [Img40h, Img41h],
    },
    {
      page: [Img42h, Img45h],
    },
    {
      page: [Img43v],
    },
    {
      page: [Img44v],
    },
    {
      page: [Img47v],
    },
    {
      page: [Img54h, Img55h],
    },
    {
      page: [Img56h, Img57h],
    },
    {
      page: [Img58v],
    },
    {
      page: [Img61h, Img62h],
    },
    {
      page: [Img66h, Img67h],
    },
    {
      page: [Img69h, Img70h],
    },
    {
      page: [Img71h, Img72h],
    },
  ]
  const pagination = {
    clickable: true,
    renderCustom: function () {
      return "<span></span>"
    },
  }
  return (
    <>
      <Swiper
        pagination={pagination}
        modules={[Pagination]}
        slidesPerView={1}
        slidesPerGroup={1}
      >
        {imagesMobile.map((item, i) => (
          <SwiperSlide
            key={i}
            className="items-center justify-items-center pb-6"
          >
            {item.page.map((image, j) => (
              <img
                loading="lazy"
                key={j}
                src={image}
                id={"Img" + j}
                className="mt-4"
                alt=""
              />
            ))}
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  )
}

function SlideShow() {
  if (typeof window !== "undefined") {
    var w = window.innerWidth
  }
  const [width, setWidth] = useState(w)
  const breakpoint = 1024

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth))
  }, [])

  return width < breakpoint ? <Mobile /> : <Desktop />
}

export default SlideShow
