import React, { useState } from "react"
//images
import BoxImg from "../images/careers/box.svg"
import FruitImg from "../images/careers/fruit.svg"
import HealthcheckImg from "../images/careers/healthcheck.svg"
import InsuranceImg from "../images/careers/insurance.svg"
import PhysioImg from "../images/careers/physio.svg"
import SwimImg from "../images/careers/swim.svg"
import TennisImg from "../images/careers/tennis.svg"
import WinterImg from "../images/careers/winter.svg"
import BirthdayImg from "../images/careers/birthday.svg"
import CoursesImg from "../images/careers/courses.svg"
import GrowthImg from "../images/careers/growth.svg"
import HorizonsImg from "../images/careers/horizons.svg"
import IdeaImg from "../images/careers/idea.svg"
import MentoringImg from "../images/careers/mentoring.svg"
import RetrospectionsImg from "../images/careers/retrospections.svg"
import SquadhourImg from "../images/careers/squadhour.svg"
import TeamspiritImg from "../images/careers/teamspirit.svg"
import TogetherImg from "../images/careers/together.svg"
import TraditionalImg from "../images/careers/traditional.svg"
import WaterImg from "../images/careers/water.svg"

import "./ActiveTab.css"

const PerksAndBenefits = (props) => {
  const types = [
    props.sport.title,
    props.health.title,
    props.fun.title,
    props.growth.title,
  ]
  const [currentTab, setCurrentTab] = useState(types[0])
  const benefits = [
    {
      title: props.sport.title,
      activities: props.sport.activities,
      descriptions: props.sport.descriptions,
      icons: [PhysioImg, TennisImg, SwimImg, SquadhourImg, BoxImg, IdeaImg],
    },
    {
      title: props.health.title,
      activities: props.health.activities,
      descriptions: props.health.descriptions,
      icons: [InsuranceImg, HealthcheckImg, WinterImg, FruitImg],
    },
    {
      title: props.fun.title,
      activities: props.fun.activities,
      descriptions: props.fun.descriptions,
      icons: [
        WaterImg,
        TraditionalImg,
        HorizonsImg,
        TogetherImg,
        BirthdayImg,
        TeamspiritImg,
      ],
    },
    {
      title: props.growth.title,
      activities: props.growth.activities,
      descriptions: props.growth.descriptions,
      icons: [GrowthImg, CoursesImg, MentoringImg, RetrospectionsImg],
    },
  ]

  const handleTabClick = (e) => {
    setCurrentTab(e.target.id)
  }
  const renderTabs = (withTitle = false) => {
    return benefits.map((tab, i) => (
      <div className="w-full">
        <button
          key={i}
          id={tab.title}
          disabled={currentTab === `${tab.title}`}
          onClick={handleTabClick}
          className="bg-gray-200 h-1.5 w-full"
        ></button>
        {withTitle && (
          <h3
            id={tab.title}
            className="font-black text-sm lg:text-lg opacity-50 cursor-pointer"
            onClick={handleTabClick}
          >
            {tab.title}
          </h3>
        )}
      </div>
    ))
  }

  return (
    <div className="container text-gray-900 not-italic tracking-wide mt-8">
      <div className="benefits grid grid-cols-4 grid-flow-row justify-items-start gap-2">
        {renderTabs(true)}
      </div>

      {benefits.map((tab, i) => (
        <div key={i}>
          {currentTab === `${tab.title}` && (
            <div className="content grid grid-cols-1 lg:grid-cols-3 gap-8 mt-16">
              {tab.activities.map((item, j) => (
                <div key={j} className="w-68 bg-gray-200">
                  <div className="m-10" id="content">
                    <img
                      src={tab.icons[j]}
                      alt={item}
                      className="h-12 w-12 object-scale-down"
                    />
                    <h2 className="font-black text-lg my-1">{item}</h2>
                    {j == 0 && i == 3 ? (
                      <p className="font-light text-sm tracking-wide">
                        {tab.descriptions[j]}{" "}
                        <a
                          href="https://weekdone.com"
                          className="text-green-600 font-black"
                        >
                          Weekdone
                        </a>
                        {tab.descriptions[j + 1]}
                      </p>
                    ) : (
                      <p className="font-light text-sm tracking-wide">
                        {i == 3 ? tab.descriptions[j + 1] : tab.descriptions[j]}
                      </p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}

      <div className="benefits grid grid-cols-4 grid-flow-row justify-items-start gap-2 lg:hidden">
        {renderTabs()}
      </div>
    </div>
  )
}

export default PerksAndBenefits
