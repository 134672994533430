import React, { useContext } from "react"
import Helmet from "react-helmet"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Content, { HTMLContent } from "../components/Content"
import PerksAndBenefits from "../components/PerksAndBenefits"
import BrainImg from "../images/careers/brain.svg"
import HandsImg from "../images/careers/hands.svg"
import HeartImg from "../images/careers/heart.svg"
import PcImg from "../images/careers/pc.svg"
import Makit from "../images/careers/makit-titulbilde.jpg"
import SlideShow from "../components/SlideShow"
import Button from "../components/Button"
import { LanguagePrefixContext } from "../components/Layout"

export const CareersPageTemplate = ({
  title,
  content,
  contentComponent,
  t,
}) => {
  const langPrefix = useContext(LanguagePrefixContext)
  const PageContent = contentComponent || Content
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="container relative mx-auto p-10 lg:px-32 lg:pt-20 text-gray-900 leading-7 not-italic w-full pb-0 lg:pb-20">
        <div className="lg:w-1/2">
          <h2 className="font-semibold text-2xl lg:text-4xl tracking-wide max-w-xl leading-snug mr-4">
            {t.join} <span className="text-green-600">{t.family}</span>
          </h2>
          <PageContent
            className="font-light text-lg text-gray-500 mt-2 lg:max-w-xl md:max-w-md"
            content={content}
          />
          <div className="flex flex-col mt-10 mr-8">
            <div className="flex-grow">
              <p className="font-light text-lg text-gray-500">
                {t.description}
              </p>
            </div>
          </div>
          <div className="flex justify-content-start text-center mt-8">
            <Button className="bg-green-400" to={`${langPrefix}vacancies`}>
              {t.openPositionsButtonText}
            </Button>
          </div>
        </div>

        <div className="w-screen -ml-10 mt-10 md:w-full md:ml-0 lg:absolute lg:-right-1 lg:top-24 lg:w-1/2 lg:h-3/4 lg:-mt-8 lg:ml-0 xl:-right-10">
          <img loading="lazy" src={Makit} className="lg:h-96" alt=""></img>
        </div>
      </div>

      <div className="bg-gray-200 flex-shrink-0 pb-8 lg:pb-24 text-gray-900 leading-7 tracking-wide pt-4 lg:pt-20">
        <div className="container px-10 py-10 mx-auto">
          <div className="flex flex-wrap">
            <div className="w-full lg:mx-24">
              <p className="font-black text-sm text-green-600">{t.culture}</p>
              <h2 className="font-semibold text-2xl lg:text-3xl mt-2">
                {t.environment}
              </h2>
              <p className="font-light text-base mt-6">{t.valuesDescription}</p>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mt-10">
                <div className="lg:w-96 font-light text-lg inline-flex">
                  <img src={HeartImg} alt="" className="w-14 h-14" />
                  <div className="ml-4">
                    <h3 className="font-black text-base lg:text-lg">
                      {t.healthAndSport}
                    </h3>
                    <p className="font-light text-sm text-gray-500 mt-2">
                      {t.healthAndSportDescription}
                    </p>
                  </div>
                </div>
                <div className="lg:w-96 font-light text-lg inline-flex">
                  <img src={PcImg} alt="" className="w-14 h-14" />
                  <div className="ml-4">
                    <h3 className="font-black text-base lg:text-lg">
                      {t.workEnvironment}
                    </h3>
                    <p className="font-light text-sm text-gray-500 mt-2">
                      {t.workEnvironmentDescription}
                    </p>
                  </div>
                </div>
                <div className="lg:w-96 font-light text-lg inline-flex">
                  <img src={BrainImg} alt="" className="w-14 h-14" />
                  <div className="ml-4">
                    <h3 className="font-black text-base lg:text-lg">
                      {t.growth}
                    </h3>
                    <p className="font-light text-sm text-gray-500 mt-2">
                      {t.growthDescription}
                    </p>
                  </div>
                </div>
                <div className="lg:w-96 font-light text-lg inline-flex">
                  <img src={HandsImg} alt="" className="w-14 h-14" />
                  <div className="ml-4">
                    <h3 className="font-black text-base lg:text-lg">
                      {t.giveOthers}
                    </h3>
                    <p className="font-light text-sm text-gray-500 mt-2">
                      {t.giveOthersDescription}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container flex flex-col lg:flex-row mx-auto p-10 lg:px-32 lg:pt-24 h-100% mb-4 leading-7 not-italic">
        <div className="flex flex-col flex-grow">
          <h2 className="font-semibold text-2xl lg:text-3xl tracking-wide max-w-xl leading-snug">
            {t.perks}
            <span className="text-green-600 text-2xl lg:text-3xl">
              {t.benefits}
            </span>
          </h2>
          <PerksAndBenefits
            sport={t.benefitsList.sport}
            health={t.benefitsList.health}
            fun={t.benefitsList.fun}
            growth={t.benefitsList.growth}
          />
        </div>
      </div>
      <div className="container flex flex-col lg:flex-row lg:px-32 lg:pt-4 px-10 mx-auto">
        <div className>
          <h2 className="font-semibold text-2xl lg:text-3xl">
            {t.everydayLife}
            <span className="text-green-600 text-2xl lg:text-3xl"> MAK IT</span>
          </h2>
          <p className="font-light text-sm text-gray-500 mt-2 lg:mt-8">
            {t.photosDescription}
          </p>
        </div>
      </div>
      <div className="slide-wrapper mx-10 md:mx-32 lg:mx-48">
        <SlideShow />
      </div>

      <div className="container flex flex-col mb-32 mt-20 mx-auto p-10 lg:px-32 lg:pt-24 lg:mb-40">
        <div className="text-center">
          <h3 className="font-semibold text-2xl lg:text-3xl">
            {t.join}{" "}
            <span className="text-green-600 text-2xl lg:text-3xl">
              {t.family}
            </span>
          </h3>
          <p className="font-light text-lg text-center text-gray-500 my-4">
            {t.description}
          </p>
          <div className="mt-8">
            <Button to={`${langPrefix}vacancies`} className="bg-green-400">
              {t.openPositionsButtonText}
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

CareersPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const CareersPage = ({ data, location }) => {
  const { markdownRemark: post } = data

  return (
    <Layout location={location}>
      <CareersPageTemplate
        title={post.frontmatter.title}
        contentComponent={HTMLContent}
        content={post.html}
        t={post.frontmatter}
      />
    </Layout>
  )
}

CareersPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default CareersPage

export const CareersPageQuery = graphql`
  query CareersPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        openPositionsButtonText
        join
        family
        description
        culture
        environment
        valuesDescription
        healthAndSport
        workEnvironment
        growth
        giveOthers
        healthAndSportDescription
        workEnvironmentDescription
        growthDescription
        giveOthersDescription
        perks
        benefits
        everydayLife
        photosDescription
        benefitsList {
          sport {
            title
            activities
            descriptions
          }
          health {
            title
            activities
            descriptions
          }
          fun {
            title
            activities
            descriptions
          }
          growth {
            title
            activities
            descriptions
          }
        }
      }
    }
  }
`
